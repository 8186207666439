// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebarRow {
    display: flex;
    align-items: center;
    padding: 15px 20px;
}

.sidebarRow__icon {
    color: #fff;
    font-size: 1.4rem !important;
}

.sidebarRow__title {
    flex: 1 1;
    margin-left: 18px;
    font-size: 1rem;
    font-weight: 500;
}
.selected{
    color: #ff2120;;
}
.sidebarRow:hover {
    cursor: pointer;
}

.sidebarRow:hover>.sidebarRow__title {
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Components/Sidebar/SidebarRow/sidebarRow.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,4BAA4B;AAChC;;AAEA;IACI,SAAO;IACP,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".sidebarRow {\n    display: flex;\n    align-items: center;\n    padding: 15px 20px;\n}\n\n.sidebarRow__icon {\n    color: #fff;\n    font-size: 1.4rem !important;\n}\n\n.sidebarRow__title {\n    flex: 1;\n    margin-left: 18px;\n    font-size: 1rem;\n    font-weight: 500;\n}\n.selected{\n    color: #ff2120;;\n}\n.sidebarRow:hover {\n    cursor: pointer;\n}\n\n.sidebarRow:hover>.sidebarRow__title {\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
