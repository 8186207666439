import React from "react";
import { Route, Redirect } from "react-router-dom";

function ProtectedRoutes({ component: Component, ...restOfProps }) {
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export default ProtectedRoutes;
