import axios from "../Components/Axios/Axios";
import React, { useEffect, useState } from "react";
import { Container, Button, Card, Form, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import Loader from "../Components/Spinner/Spinner";
import requests from "../Components/Requests/Requests";

function AddServers() {
  const [filePath, setFilePath] = useState("");
  const [fileName, setFileName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [getCountries, setGetCountries] = useState([]);
  const [status, setStatus] = useState("0");
  const [provider, setProvider] = useState("");
  const [serverType, setServerType] = useState("0");
  const [showError, SetShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    countryList();
  }, []);

  const add = async () => {
    setLoading(true);
    const body = {
      country_id: countryName,
      file_path: filePath,
      is_active: status,
      file_name: fileName,
      server_provider: provider,
      type: serverType,
    };
    // console.log("body.........",body);
    if (filePath.length && fileName.length && provider.length > 0) {
      try {
        const responce = await axios.post(requests.addServer, body);
        // console.log("server added....",responce);
        if (responce.data.Success === 1) {
          setLoading(false);
          toast.success("Server Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2500);
        } else {
          toast.warning(`addserver Something went wrong`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        toast.warning(err, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          setLoading,
        });
      }
    } else {
      setLoading(false);
      toast.warning(`All fields are Required`, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const countryList = async () => {
    try {
      let responce = await axios.get(requests.getCountry);
      // console.log(responce);
      setGetCountries(responce.data.data);
      setCountryName(responce.data.data[0].id);
      // var abc = responce.data.data[0].id;
      // console.log("abc.....",abc);
    } catch (err) {
      toast.warning(err, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const addNewServer = () => {
    let linkValidation = filePath.substring(0, 4);
    if (filePath.length && fileName.length && status.length !== 0) {
      if (linkValidation == "http") {
        add();
      } else {
        SetShowError(true);
      }
    } else {
      // console.log(
      //   filePath.length,
      //   fileName.length,
      //   countryName.length,
      //   status.length
      // );
      toast.warning("All the Fields are Required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // console.log(serverName, serverLink, serverLocation, countryName);
  };
  return (
    <>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card>
          <Card.Header className="heading">Add Server</Card.Header>
          <Card.Body>
            <Row>
              <Col className="main">
                <Form.Group className="mb-3">
                  <Form.Label>
                    File Path <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="File Path"
                    onChange={(e) => setFilePath(e.target.value)}
                  />
                  {showError && (
                    <h6 style={{ color: "red", fontSize: 14, padding: 8 }}>
                      File Path needs to be a link starting with https:
                    </h6>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    File Name <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="File Name"
                    onChange={(e) => setFileName(e.target.value)}
                  />
                </Form.Group>

                {/* <Form.Group className="mb-3">
                  <Form.Label>Server Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Server Location"
                    onChange={(e) => SetServerLocation(e.target.value)}
                  />
                </Form.Group> */}

                <Form.Group className="mb-3">
                  <Form.Label>
                    Countries <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Select onChange={(e) => setCountryName(e.target.value)}>
                    {getCountries.map((country, index) => {
                      return (
                        <option key={index} value={country.id}>
                          {/* <img src={country.country_flag}></img> */}
                          {country.country_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Status <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="0">Inactive</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Provider <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Provider"
                    onChange={(e) => setProvider(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Server Type <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Select
                    value={serverType}
                    onChange={(e) => setServerType(e.target.value)}
                  >
                    <option value="0">Paid</option>
                    <option value="1">Free</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="footer">
            {loading == true ? (
              <Loader margin={0} />
            ) : (
              <Button
                className="submit-btn"
                onClick={() => addNewServer()}
                variant="primary"
              >
                Submit
              </Button>
            )}
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default AddServers;
