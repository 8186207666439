// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
    max-width: 540px;
    margin: auto;
}

.logo-container {
    text-align: center;
    margin-bottom: 50px;
}

.form {
    background-color: #343a40;
    padding: 40px;
    border-radius: 10px;
    color: #fff;
}

.button {
    height: 40px !important;
    background-color: #ff2120;
    border: none;
}
.button:hover {
    height: 40px !important;
    background-color: #ff2120;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/login.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".login-form {\n    max-width: 540px;\n    margin: auto;\n}\n\n.logo-container {\n    text-align: center;\n    margin-bottom: 50px;\n}\n\n.form {\n    background-color: #343a40;\n    padding: 40px;\n    border-radius: 10px;\n    color: #fff;\n}\n\n.button {\n    height: 40px !important;\n    background-color: #ff2120;\n    border: none;\n}\n.button:hover {\n    height: 40px !important;\n    background-color: #ff2120;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
