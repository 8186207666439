import React from "react";
import { Spinner } from "react-bootstrap";

function Loader({margin}) {
  return (
    <Spinner
      style={{ display: "flex", margin: {margin} }}
      as="span"
      variant="warning"
      size="lg"
      role="status"
      aria-hidden="true"
      animation="border"
    />
  );
}

export default Loader;
