import React, { useState } from "react";
import { Link, useHistory, NavLink } from "react-router-dom";
import { Navbar, Offcanvas, Nav, Container, Button } from "react-bootstrap";
//Custom Component
import SidebarRow from "../SidebarRow/SidebarRow";
import { MenuItems } from "../MenuItems/MenuItems";
import logo from "../../../Assets/Images/voixLogo.svg";
import { removeUserSession } from "../../../Utils/Common/Common";
import "./sidebar.css";

function Sidebar() {
  const history = useHistory();

  const logout = () => {
    removeUserSession();
    history.push("/");
  };

  const [showProfile, SetShowProfile] = useState(false);
  const [selected, setSelected] = useState(false);
  // const [show, setShow] = useState(false);
  return (
    <>
      <Navbar fixed="top" bg="dark" varient="dark" expand={false}>
        <Container fluid>
          <div className="left-items">
            <Navbar.Toggle aria-controls="offcanvasNavbar" />
            <Link to="/">
              <Navbar.Brand>
                <img src={logo} width={120} />
              </Navbar.Brand>
            </Link>
          </div>
          <div className="right-items">
            <Navbar.Brand>
              <Button
                onClick={() => SetShowProfile(!showProfile)}
                className="btn-sm user-btn"
              >
                username
              </Button>
            </Navbar.Brand>
          </div>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <img className="modal-logo" src={logo} />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {MenuItems.map((item, index) => {
                  return (
                    <div key={index}>
                      <NavLink className={"sidebarItems"} to={item.url}>
                        <SidebarRow status={false} title={item.title} />
                      </NavLink>
                      {/* {item.dropdown ? (
                        <>
                          <div
                            className="dropdownList"
                            onClick={() => setShow(!show)}
                          >
                            <SidebarRow status={false} title={item.title} />
                          </div>
                          <Link
                            key={index}
                            to={item.dropdown.url}
                            className="sidebarItems"
                          >
                            <SidebarRow
                              // clicked={}
                              status={false}
                              title={item.dropdown.title}
                            />
                          </Link>
                        </>
                      ) : (
                        <Link
                          key={index}
                          to={item.url}
                          className="sidebarItems"
                        >
                          <SidebarRow status={false} title={item.title} />
                        </Link>
                      )} */}
                    </div>
                  );
                })}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {showProfile && (
        <div className="avatar-dropdown">
          <h4 className="avatar-item" onClick={() => logout()}>
            {/* <LogoutIcon style={{ fontSize: 30, paddingRight: 10 }} /> */}
            Logout
          </h4>
        </div>
      )}
    </>
  );
}

export default Sidebar;
