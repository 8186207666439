import React, { useEffect, useState, forwardRef } from "react";
import { Container, Card, Form, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MaterialTable from "material-table";
import instance from "../Components/Axios/Axios";
import {
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import axios from "../Components/Axios/Axios";
//Custom Components
import Loader from "../Components/Spinner/Spinner";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import requests from "../Components/Requests/Requests";

const ShowServers = () => {
  const [countries, setCountries] = useState([]);
  const [data, setData] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState();
  const [Tableloading, setTableLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);

  useEffect(() => {
    if (selectedCountries) {
      getServers();
    }
  }, [selectedCountries]);

  useEffect(() => {
    getCountries();
    // console.log(selectedCountries);
  }, []);

  //   const submit = async () => {};

  const getServers = async () => {
    setTableLoading(true);
    // console.log("HImijv....");
    const body = { vpn_cc: selectedCountries };
    try {
      const responce = await instance.post(requests.getServer, body, {
        // headers: {
        //   authorization:
        //     "cb70755a985f963d64ab1bb0541b0980e0edf54931f00c9f7760d17477a90",
        // },
      });
      if (responce.data.Success == 1) {
        setTableLoading(false);
        console.log(responce.data);
        setData(responce.data.data);
      } else {
        setTableLoading(false);
        toast.warning(`getserver something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      setTableLoading(false);
      toast.warning(`getserver API ${err}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const getCountries = async () => {
    setSelectLoading(true);
    try {
      let responce = await instance.get(requests.getCountry);
      if (responce.data.Success === 1) {
        setSelectLoading(false);
        // console.log(responce.data.data);
        setCountries(responce.data.data);
        setSelectedCountries("all");
      } else {
        setSelectLoading(false);
        toast.warning(`getcountry API something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // console.log("data is abc..", responce);
    } catch (err) {
      setSelectLoading(false);
      toast.warning(`getcountry API ${err}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <>
      <Sidebar />
      <Container className="content">
        <Card>
          <Card.Header className="heading">Show Servers</Card.Header>
          <Card.Body>
            <Row>
              {selectLoading === true ? (
                <Loader margin={"auto"} />
              ) : (
                <Form.Group className="mb-3">
                  <Form.Label>Select Country</Form.Label>
                  <Form.Select
                    value={selectedCountries}
                    // defaultValue={optionArray[index]}
                    onChange={(e) => setSelectedCountries(e.target.value)}
                  >
                    <option value="all">All Countries</option>
                    {countries.map((country, index) => {
                      return (
                        <option key={index} value={country.country_code}>
                          {/* <img src={country.country_flag}></img> */}
                          {country.country_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              )}
            </Row>
          </Card.Body>
          <Card.Footer className="footer"></Card.Footer>
        </Card>
        <Card className="card">
          <Card.Header className="heading">Show Servers</Card.Header>
          <Card.Body className="body">
            {Tableloading === true ? (
              <Loader />
            ) : (
              <MaterialTable
                data={data}
                icons={tableIcons}
                columns={[
                  {
                    title: "#",
                    field: "tableData.id",
                    render: (rowData) => rowData.tableData.id + 1,
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      borderLeft: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                    filtering: false,
                  },
                  {
                    title: "Country Name",
                    field: "country_name",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                    filtering: false,
                  },
                  {
                    title: "Country Flag",
                    render: (rowData) => (
                      <img style={{ width: 40 }} src={rowData.country_flag} />
                    ),
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "File Name",
                    field: "file_name",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                    filtering: false,
                  },
                  {
                    title: "Server Type",
                    field: "type",
                    lookup: {
                      1: "Free",
                      0: "Paid",
                    },
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  },
                  {
                    title: "Provider",
                    field: "server_provider",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                    filtering: false,
                  },
                  {
                    title: "File Path",
                    render: (rowData) => (
                      <a href={rowData.file_path}>{rowData.file_path}</a>
                    ),
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "Is Active",
                    field: "is_active",
                    lookup: {
                      1: "Active",
                      0: "Inactive",
                    },
                    cellStyle: (rowData) =>
                      rowData === "Active"
                        ? {
                            borderRight: "1px solid #e0e0e0",
                            color: "green",
                            fontSize: 18,
                            fontWeight: "bold",
                          }
                        : {
                            borderRight: "1px solid #e0e0e0",
                            color: "red",
                            fontWeight: "bold",
                            fontSize: 18,
                          },
                  },
                  {
                    title: "Edit",
                    render: (rowData) => (
                      <Link to={{ pathname: "/editServers", state: rowData }}>
                        <Button variant="primary" size="md">
                          Edit
                        </Button>
                      </Link>
                    ),
                  },
                ]}
                options={{
                  filtering: true,
                  pageSizeOptions: [
                    5, 10,
                    // { value: data.length, label: data.length },
                  ],
                  exportButton: true,
                  showTitle: false,
                  // pageSize: 10,
                }}
              />
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default ShowServers;
