import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Button, Card, Form, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import axios from "../Components/Axios/Axios";
import requests from "../Components/Requests/Requests";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";

function EditServers(props) {
  const data = props.location.state;
  const [serverId, setServerId] = useState(data.id);
  const [countryId, setCountryId] = useState(data.country_id);
  const [filePath, setFilePath] = useState(data.file_path);
  const [fileName, setFileName] = useState(data.file_name);
  const [countries, setCountries] = useState([]);
  const [countryName, setCountryName] = useState(data.country_name);
  const [countryCode] = useState(data.country_code);
  const [countryFlag, setCountryFlag] = useState(data.country_flag);
  const [status, setStatus] = useState(data.is_active);
  const [provider, setProvider] = useState(data.server_provider);
  const [serverType, setServerType] = useState(data.type);
  const [showError, SetShowError] = useState(false);

  const history = useHistory();

  // console.log(props);
  useEffect(() => {
    getCountries();
  }, []);

  const Edit = async () => {
    const body = {
      server_id: serverId,
      file_path: filePath,
      is_active: status,
      file_name: fileName,
      server_provider: provider,
      type: serverType,
    };
    try {
      const responce = await axios.post(requests.editServer, body);
      console.log(responce);
      if (responce.data.Success === 1) {
        SetShowError(false);
        toast.success("Server Info Editted Successfully!!", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          history.push("/showServers");
        }, 2500);
      } else {
        toast.warning(`editserver API Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.warning(`editserver API ${err}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  // console.log(responce);

  const EditServers = () => {
    let linkValidation = filePath.substring(0, 4);
    if (linkValidation == "http") {
      Edit();
    } else {
      SetShowError(true);
    }
    // console.log("Editted....");
  };

  const getCountries = async () => {
    // setSelectLoading(true);
    try {
      let responce = await axios.get(requests.getCountry);
      if (responce.data.Success === 1) {
        // console.log("countries....", responce.data.data);
        setCountries(responce.data.data);
        // setSelectLoading(false);
      } else {
        toast.warning(`get country something went wrong`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // console.log("data is abc..", responce);
    } catch (err) {
      toast.warning(`getcountry API ${err}`, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card>
          <Card.Header className="heading">Edit Server</Card.Header>
          <Card.Body>
            <Row>
              <Col className="main">
                {/* <Form.Group className="mb-3">
                  <Form.Label>Server Id</Form.Label>
                  <Form.Control
                    value={serverId}
                    type="text"
                    placeholder="Server Id"
                    disabled
                    onChange={(e) => setServerId(e.target.value)}
                  />
                </Form.Group> */}

                <Form.Group className="mb-3">
                  <Form.Label>
                    File Path <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    value={filePath}
                    type="text"
                    placeholder="File Path"
                    onChange={(e) => setFilePath(e.target.value)}
                  />
                  {showError && (
                    <h6 style={{ color: "red", fontSize: 14, padding: 8 }}>
                      File Path needs to be a link starting with https:
                    </h6>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    File Name <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="File Name"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Country Flag <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    value={countryFlag}
                    type="text"
                    placeholder="Country Flag Image"
                    onChange={(e) => setCountryFlag(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Country <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Select onChange={(e) => setCountryName(e.target.value)}>
                    <option key={fileName} value={countryCode}>
                      {countryName}
                    </option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Status <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    {status == 0 ? (
                      <>
                        <option value="0">Inactive</option>
                        <option value="1">Active</option>
                      </>
                    ) : (
                      <>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </>
                    )}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Provider <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={provider}
                    placeholder="Provider"
                    onChange={(e) => setProvider(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Server Type <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Select
                    value={serverType}
                    onChange={(e) => setServerType(e.target.value)}
                  >
                    <option value="0">Paid</option>
                    <option value="1">Free</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="footer">
            <Button
              className="submit-btn"
              onClick={() => EditServers()}
              variant="primary"
            >
              Submit
            </Button>
            <Button
              onClick={history.goBack}
              className="submit-btn mx-3"
              variant="danger"
            >
              Cancel
            </Button>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default EditServers;
