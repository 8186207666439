import axios from "axios";

const instance = axios.create({
  // baseURL: "https://apiai.musycraft.ai/",
  // https://apiai.musycraft.ai/docs
  baseURL: "https://admin-api.vocalremover-voix.com/admin/v1/",
  timeout: 5000,
  headers: {
    Authorization:
      "cb70755a985f963d64ab1bb0541b0980e0edf54931f00c9f7760d17477a90",
  },
});

export default instance;
