import React, { useState } from "react";
import axios from "../Components/Axios/Axios";
import { Container, Button, Card, Form, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import CKEditor from "ckeditor4-react-advanced";
import Loader from "../Components/Spinner/Spinner";
import requests from "../Components/Requests/Requests";

function AddFaq() {
  const [title, setTitle] = useState("");
  const [editorValue, setEditorValue] = useState("");
  const [status, setStatus] = useState("0");
  const [type, setType] = useState("General");
  const [loading, setloading] = useState(false);

  const addNewFaq = async () => {
    setloading(true);
    const body = { que: title, ans: editorValue, status: status, type: type };
    try {
      const responce = await axios.post(requests.addFaq, body);
      if (responce.data.Success === 1) {
        // console.log(responce);
        toast.success("FAQ added successfully!!", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      } else {
        toast.warning(`something went wrong`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.warning(err, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // console.log("body.....", body);
  };
  return (
    <>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card>
          <Card.Header className="heading">Add FAQ</Card.Header>
          <Card.Body>
            <Row>
              <Col className="main">
                <Form.Group className="mb-3">
                  <Form.Label>FAQ Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="FAQ Title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="General">General</option>
                    <option value="Android">Android Web</option>
                    <option value="Android App">Android App</option>
                    <option value="Ios">Ios</option>
                    <option value="MacOS">Mac</option>
                  </Form.Select>
                </Form.Group>
                {type == "General" || type == "Android" ? (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>FAQ Description</Form.Label>
                      <CKEditor
                        data={"Enter FAQ Description"}
                        onChange={(evt) => setEditorValue(evt.editor.getData())}
                      />
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>FAQ Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="FAQ Description"
                        onChange={(e) => setEditorValue(e.target.value)}
                      />
                    </Form.Group>
                  </>
                )}

                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="0">Inactive</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="footer">
            {loading == true ? (
              <Loader margin={0} />
            ) : (
              <Button
                className="submit-btn"
                onClick={() => addNewFaq()}
                variant="primary"
              >
                Submit
              </Button>
            )}
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default AddFaq;
