import axios from "../Components/Axios/Axios";
import React, { useState } from "react";
import { Container, Button, Card, Form, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "react-query";
import Loader from "../Components/Spinner/Spinner";
import requests from "../Components/Requests/Requests";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";

function UploadImg() {
  const [img, setImg] = useState("");
  const getLink = async () => {
    const formData = new FormData();
    formData.append("media", img);

    const responce = await axios.post(requests.imgupload, formData);
    return responce;
  };

  const { data, status, refetch } = useQuery("img", getLink, {
    enabled: false,
  });

  console.log(status);

  const handleClick = () => {
    if (img.name) {
      refetch();
    }
  };

  return (
    <div>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card>
          <Card.Header className="heading">
            Upload Image and get Link
          </Card.Header>
          <Card.Body>
            <Row>
              <Col className="main">
                <Form.Group className="mb-3">
                  <Form.Label>Upload Image</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Blog Image"
                    onChange={(e) => setImg(e.target.files[0])}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="footer">
            {status === "loading" ? (
              <Loader margin={0} />
            ) : status === "error" ? (
              <p>There was some error....</p>
            ) : (
              <Button
                onClick={() => handleClick()}
                className="submit-btn"
                variant="primary"
              >
                Get Link
              </Button>
            )}
          </Card.Footer>
        </Card>
        {status === "success" && (
          <Form.Group className="mb-3">
            <Form.Label>Image Link</Form.Label>
            <Form.Control
              type="text"
              defaultValue={status === "success" ? data.data.data.img : ""}
              disabled
            />
          </Form.Group>
        )}
      </Container>
    </div>
  );
}

export default UploadImg;
