import axios from "../Components/Axios/Axios";
import React, { useEffect, useState } from "react";
import { Container, Button, Card, Form, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Custom Components
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import Loader from "../Components/Spinner/Spinner";
import requests from "../Components/Requests/Requests";

function AddCountry() {
  const [countryName, SetCountryName] = useState("");
  const [CountryFlag, SetCountryFlag] = useState("");
  const [CountryCode, SetCountryCode] = useState("");
  const [status, Setstatus] = useState("0");
  const [showError, SetShowError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [submitted]);

  const addNewCountry = async () => {
    setLoading(true);
    SetShowError(false);
    let linkValidation = CountryFlag.substring(0, 4);
    // console.log(countryName,CountryFlag,CountryCode,status)
    const body = {
      country_code: CountryCode,
      country_name: countryName,
      is_active: status,
      country_flag: CountryFlag,
    };
    // console.log("body........",body);
    if (countryName.length && CountryFlag.length && CountryCode.length > 0) {
      if (linkValidation == "http") {
        try {
          const responce = await axios.post(requests.addCountry, body);
          if (responce.data.Success == 1) {
            setLoading(false);
            toast.success("Country Added Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2500);
          } else {
            toast.warning(`something went wrong`, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (err) {
          toast.warning(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        SetShowError(true);
      }
    } else {
      toast.warning("All the Fields are Required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card>
          <Card.Header className="heading">Add Country</Card.Header>
          <Card.Body>
            <Row>
              <Col className="main">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Country Name <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Country Name"
                    onChange={(e) => SetCountryName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Country Flag Image <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Country Flag Image Link"
                    onChange={(e) => SetCountryFlag(e.target.value)}
                  />
                  {showError && (
                    <h6 style={{ color: "red", fontSize: 14, padding: 8 }}>
                      Country Flag need to be a link starting with https:
                    </h6>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Country Code <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Country Code"
                    onChange={(e) => SetCountryCode(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Status <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Select
                    value={status}
                    onChange={(e) => Setstatus(e.target.value)}
                  >
                    <option value="0">Inactive</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="footer">
            {loading == true ? (
              <Loader />
            ) : (
              <Button
                className="submit-btn"
                onClick={() => addNewCountry()}
                variant="primary"
              >
                Submit
              </Button>
            )}
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default AddCountry;
