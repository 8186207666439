import React, { useEffect, useState } from "react";
import axios from "../Components/Axios/Axios";
import { Container, Button, Card, Form, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import CKEditor from "ckeditor4-react-advanced";
import Loader from "../Components/Spinner/Spinner";
import requests from "../Components/Requests/Requests";
import instance from "../Components/Axios/Axios";
import { useHistory } from "react-router-dom";

function EditBlogs(props) {
  const history = useHistory();
  const [id, setId] = useState(props.location.state.id);
  const [title, setTitle] = useState("");
  const [blogImg, setBlogImg] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [blogSlug, setBlogSlug] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [editorValue, setEditorValue] = useState(
    props.location.state.desciption
  );
  const [status, setStatus] = useState(props.location.state.status);
  const [loading, setloading] = useState(false);

  let data = props.location.state;
  console.log("data......", data);

  useEffect(() => {
    defaultValues();
  }, []);

  const defaultValues = () => {
    setTitle(data.title);
    setShortDesc(data.shortdesc);
    setEditorValue(data.desciption);
    setMetaTitle(data.meta_title);
    setMetaDesc(data.meta_desc);
  };

  const editBlogsApi = async () => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("title", title);
    formData.append("desc", editorValue);
    formData.append("status", status);
    formData.append("shortdesc", shortDesc);
    formData.append("slug", title.replace(":", "").split(" ").join("-"));
    formData.append("metatitle", metaTitle);
    formData.append("metadesc", metaDesc);
    formData.append("media", blogImg);
    try {
      const responce = await instance.post(requests.editBlogs, formData);
      setloading(true);
      if (responce?.data?.Success === 1) {
        toast.success("Blog Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setloading(false);
        setTimeout(() => {
          history.push("/allBlogs");
        }, 2500);
      } else {
        toast.warn("Problem with Addblog API", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card>
          <Card.Header className="heading">Add Blogs</Card.Header>
          <Card.Body>
            <Row>
              <Col className="main">
                <Form.Group className="mb-3">
                  <Form.Label>Blog Title</Form.Label>
                  <Form.Control
                    defaultValue={title}
                    type="text"
                    placeholder="Blog Title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Short Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    defaultValue={shortDesc}
                    placeholder="Short Description"
                    onChange={(e) => setShortDesc(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Blog Description</Form.Label>
                  <CKEditor
                    data={editorValue}
                    onChange={(evt) => setEditorValue(evt.editor.getData())}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Blog Image</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Blog Image"
                    onChange={(e) => setBlogImg(e.target.files[0])}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Meta Title</Form.Label>
                  <Form.Control
                    defaultValue={metaTitle}
                    type="text"
                    placeholder="Meta Title"
                    onChange={(e) => setMetaTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Meta Description</Form.Label>
                  <Form.Control
                    defaultValue={metaDesc}
                    as="textarea"
                    rows={3}
                    placeholder="Meta Description"
                    onChange={(e) => setMetaDesc(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={0}>Inactive</option>
                    <option value={1}>Active</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="footer">
            {loading == true ? (
              <Loader margin={0} />
            ) : (
              <Button
                className="submit-btn"
                onClick={() => editBlogsApi()}
                variant="primary"
              >
                Submit
              </Button>
            )}
          </Card.Footer>
        </Card>
      </Container>
    </div>
  );
}

export default EditBlogs;
