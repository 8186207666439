import React from "react";
import "./sidebarRow.css";

const SidebarRow = ({ selected, Icon, title, status }) => {
  // useEffect(() => {}, []);
  return (
    <>
      <div className={`sidebarRow ${selected && "selected"}`}>
        {status ? <Icon className="sidebarRow__icon" /> : null}
        <h2 className="sidebarRow__title">{title}</h2>
        {/* {MenuItems.map((items, index) => {
          {
            items.dropdown && (
              <>
                <Link key={index} to={items.url}>
                  <h3 className="sidebarRow__title">{items.title}</h3>
                </Link>
              </>
            );
          }
        })} */}
      </div>
    </>
  );
};

export default SidebarRow;
