import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Button, Card, Form, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../Components/Axios/Axios";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import Loader from "../Components/Spinner/Spinner";
import requests from "../Components/Requests/Requests";

function EditCountry(props) {
  const data = props.location.state;
  const [countryId, setCountryId] = useState(data.id);
  const [countryName, setCountryName] = useState(data.country_name);
  const [countryFlag, setCountryFlag] = useState(data.country_flag);
  const [countryCode, setCountryCode] = useState(data.country_code);
  const [status, setStatus] = useState(data.is_active);
  const [showError, SetShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  // console.log(props);

  const history = useHistory();

  const Edit = async () => {
    setLoading(true);
    const body = {
      country_id: countryId,
      country_code: countryCode,
      country_name: countryName,
      is_active: status,
      country_flag: countryFlag,
    };
    try {
      const responce = await axios.post(requests.editCountry, body);
      // console.log(responce.data.Success);
      if (responce.data.Success === 1) {
        // console.log("himanshu g");
        // setLoading(false);
        toast.success("Country Editted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          history.push("/showCountries");
        }, 2500);
      } else {
        toast.warning(`Something went wrong`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.warning(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const EditCountries = () => {
    let linkValidation = countryFlag.substring(0, 4);
    if (linkValidation == "http") {
      Edit();
    } else {
      SetShowError(true);
    }
  };
  return (
    <>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card>
          <Card.Header className="heading">Edit Country</Card.Header>
          <Card.Body>
            <Row>
              <Col className="main">
                {/* <Form.Group className="mb-3">
                  <Form.Label>Country ID</Form.Label>
                  <Form.Control
                    disabled={true}
                    value={countryId}
                    type="text"
                    placeholder="Country ID"
                    onChange={(e) => setCountryId(e.target.value)}
                  />
                </Form.Group> */}

                <Form.Group className="mb-3">
                  <Form.Label>
                    Country Name <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    value={countryName}
                    type="text"
                    placeholder="Country Name"
                    onChange={(e) => setCountryName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Country Flag <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Flag Image Link"
                    value={countryFlag}
                    onChange={(e) => setCountryFlag(e.target.value)}
                  />
                  {showError && (
                    <h6 style={{ color: "red", fontSize: 14, padding: 8 }}>
                      Flag Image needs to be a link starting with https:
                    </h6>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Country Code <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    value={countryCode}
                    type="text"
                    placeholder="Country Code"
                    onChange={(e) => setCountryCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Status <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    {status == 0 ? (
                      <>
                        <option value="0">Inactive</option>
                        <option value="1">Active</option>
                      </>
                    ) : (
                      <>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </>
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="footer">
            {loading == true ? (
              <Loader margin={0} />
            ) : (
              <>
                <Button
                  className="submit-btn"
                  onClick={() => EditCountries()}
                  variant="primary"
                >
                  Submit
                </Button>{" "}
                <Button
                  onClick={history.goBack}
                  className="submit-btn mx-3"
                  variant="danger"
                >
                  Cancel
                </Button>
              </>
            )}
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default EditCountry;
