const requests = {
  getServer: `getserver`,
  addServer: `addserver`,
  editServer: `editserver`,
  editFaq: `editfaq`,
  allFaq: `adminfaqs`,
  addFaq: `addfaqnew`,
  getCountry: `getcountry`,
  addCountry: `addcountry`,
  editCountry: `editcountry`,
  allBlogs: `bloglist`,
  editBlogs: `editblog`,
  addBlogs: `addblog`,
  imgupload: `imgupload`,
};

export default requests;
